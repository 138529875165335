export const ENDPOINTS = {
  getHeader: "/home/header",
  home: "/home/data",
  signup: "/auth/register",
  upload: "/upload/:section",
  createCourse: "/courses/create",
  createCourseModule: "/courses/module/create",
  updateCourseModule: "/courses/module/update",
  checkNameAvailability: "/courses/module/check-name",
  adminListCourses: "/courses/admin/list",
  listCourses: "/courses/list",
  getCourseDetails: "/courses/single/:slug",
  updateCourse: "/courses/update/:slug",
  ipAPI: "/api/json",
  deleteCourse: "/courses/delete/:slug",
  listByRank: "/courses/list/rank",
  searchCourse: "/courses/search",
  loginWithEmail: "/auth/login",
  userProfile: "/users/profile",
  generateNewAccessToken: "/auth/refresh",
  enquiryAdd: "/enquiry/add",

  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/reset-password",
};
