import { AxiosWrapper } from "../axios";
import { ENDPOINTS } from "../endpoints";

/* Types */
import type { Tokens } from "../types";

/* Types */
import type {
  EmailLoginPayloadType,
  ForgotPasswordRes,
  LoginResponse,
  RefreshTokenRes,
  ResetPasswordPayload,
  ResetPasswordRes,
  SignupPayloadType,
  SignupResponseType,
} from "./types";

export const Signup = async (
  payload: SignupPayloadType,
): Promise<SignupResponseType> => {
  return await AxiosWrapper({
    method: "POST",
    url: ENDPOINTS.signup,
    body: payload,
  });
};

export const LoginWithEmail = async (body: EmailLoginPayloadType) => {
  return await AxiosWrapper<LoginResponse, EmailLoginPayloadType>({
    method: "POST",
    url: ENDPOINTS.loginWithEmail,
    body,
    withCredentials: true,
  });
};

export const getUserProfile = async (tokens: Tokens) => {
  return await AxiosWrapper<LoginResponse, void>({
    isProtectedRoute: true,
    method: "GET",
    url: ENDPOINTS.userProfile,
    refreshToken: tokens.refreshToken,
    token: tokens.token,
  });
};

export const getNewAccessToken = async (refreshToken: string) => {
  return await AxiosWrapper<RefreshTokenRes, void>({
    isProtectedRoute: true,
    method: "POST",
    url: ENDPOINTS.generateNewAccessToken,
    headers: {
      "x-refresh-token": refreshToken,
    },
  });
};

export const forgotPassword = async (email: string) => {
  return await AxiosWrapper<ForgotPasswordRes, { email: string }>({
    method: "POST",
    url: ENDPOINTS.forgotPassword,
    body: { email },
  });
};

export const resetPassword = async (body: ResetPasswordPayload) => {
  return await AxiosWrapper<ResetPasswordRes, ResetPasswordPayload>({
    method: "POST",
    url: ENDPOINTS.resetPassword,
    body,
  });
};
