import { useMutation } from "@tanstack/react-query";

/* API */
import {
  Signup,
  getNewAccessToken,
  LoginWithEmail,
  forgotPassword,
  resetPassword,
} from "./handler";

/* Types */
import type { ResponseType } from "../types";
import type {
  LoginResponse,
  SignupPayloadType,
  SignupResponseType,
  EmailLoginPayloadType,
  RefreshTokenRes,
  ForgotPasswordRes,
  ResetPasswordRes,
  ResetPasswordPayload,
} from "./types";

/* API */

export const useSignup = () => {
  const mutation = useMutation<
    SignupResponseType,
    ResponseType,
    SignupPayloadType
  >({
    mutationFn: Signup,
  });

  return mutation;
};

export const useLogin = () => {
  const mutation = useMutation<
    LoginResponse,
    ResponseType,
    EmailLoginPayloadType
  >({
    mutationFn: LoginWithEmail,
  });

  return mutation;
};

export const useForgotPassword = () => {
  const mutation = useMutation<ForgotPasswordRes, ResponseType, string>({
    mutationFn: forgotPassword,
  });

  return mutation;
};

export const useResetPassword = () => {
  const mutation = useMutation<
    ResetPasswordRes,
    ResponseType,
    ResetPasswordPayload
  >({
    mutationFn: resetPassword,
  });

  return mutation;
};

export const useGenerateNewAccessToken = () => {
  const mutation = useMutation<RefreshTokenRes, ResponseType, string>({
    mutationFn: getNewAccessToken,
  });

  return mutation;
};
